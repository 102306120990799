import { datadogRum } from "@datadog/browser-rum";
import { LocalStorageService } from "./localStorage.service";

export class DataDogService {
  static setUserData() {
    const id = LocalStorageService.getGuestId();
    const name = LocalStorageService.getName();
    const email = LocalStorageService.getEmail();
    if (process.env.NEXT_PUBLIC_ENV === "prod") {
      datadogRum.setUser({
        id,
        name,
        email,
      });
    }
  }
}
