/* eslint-disable react-hooks/exhaustive-deps */
"use client";

import { AddonModel } from "@/models/addon.model";
import { AddonGroupModel } from "@/models/addonGroup.model";
import { AllergyTypeModel } from "@/models/allergyType.model";
import { CategoryModel } from "@/models/category.model";
import { FeeModel } from "@/models/fee.model";
import { ItemModel } from "@/models/item.model";
import { LocationModel } from "@/models/location.model";
import { SubcategoryModel } from "@/models/subcategory.model";
import { TaxExemptionModel } from "@/models/taxExemption.model";
import { TaxGroupModel } from "@/models/taxGroup.model";
import { TaxItemModel } from "@/models/taxItem.model";
import { ISlot, Timetable } from "@/models/timetable.model";
import { DataDogService } from "@/services/dataDog.service";
import { findOpenSlot } from "@/utils/timetable";
import React, { Dispatch, createContext, useEffect, useReducer } from "react";

export const setMetadata = "[METADATA CONTEXT] SET METADATA";
export const setCurrentSlot = "[METADATA CONTEXT] SET CURRENT SLOT";

export type ActionType = {
  type: string;
  payload: any;
};

export interface MetadataStateModel {
  categories: CategoryModel[];
  subcategories: SubcategoryModel[];
  items: ItemModel[];
  addons: AddonModel[];
  addonGroups: AddonGroupModel[];
  allergyTypes: AllergyTypeModel[];
  taxItems: TaxItemModel[];
  taxGroups: TaxGroupModel[];
  taxExemptions: TaxExemptionModel[];
  fees: FeeModel[];
  location: LocationModel;
  oosTimetable: Timetable;
  locationTimetable: Timetable;
  isLoading: boolean;
  isHomeAlertShown: boolean;
  currentSlot: ISlot | null;
}

export const initialState: MetadataStateModel = {
  categories: [],
  subcategories: [],
  items: [],
  addons: [],
  addonGroups: [],
  allergyTypes: [],
  taxItems: [],
  taxGroups: [],
  taxExemptions: [],
  fees: [],
  location: {} as LocationModel,
  oosTimetable: {
    timetable: [],
  } as Timetable,
  locationTimetable: {
    timetable: [],
  } as Timetable,
  isLoading: true,
  isHomeAlertShown: false,
  currentSlot: null,
};

const reducer = (state: MetadataStateModel, action: ActionType) => {
  switch (action.type) {
    case setMetadata:
      return {
        ...state,
        ...action.payload,
        isLoading: false,
      };
    case setCurrentSlot:
      return {
        ...state,
        currentSlot: action.payload,
      };
    default:
      return state;
  }
};

export const MetadataContext = createContext<{
  state: any;
  dispatch: Dispatch<ActionType>;
}>({ state: initialState, dispatch: () => null });

export const MetadataContextProvider = ({
  children,
  initialData,
}: {
  children: React.ReactNode;
  initialData: MetadataStateModel;
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  
  useEffect(() => {
    dispatch({ type: setMetadata, payload: initialData });

    let timeoutId;
    const setSlotData = (metadata) => {
      DataDogService.setUserData();
      const slotData = findOpenSlot(
        metadata.oosTimetable.timetable,
        metadata.location.timezone
      );
      dispatch({ type: setCurrentSlot, payload: slotData });
      timeoutId = setTimeout(() => setSlotData(metadata), 5000);
    };

    setSlotData(initialData);

    return () => clearTimeout(timeoutId);
  }, [initialData]);

  return (
    <MetadataContext.Provider value={{ state, dispatch }}>
      {children}
    </MetadataContext.Provider>
  );
};
